import React from 'react';
import { Website } from "@repo/common/entities";
import { WebsiteCard } from '../WebsiteCard';
export const WebsiteList: React.FC<{
  websites: Website[];
}> = ({
  websites
}) => {
  return <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-2 md:gap-3 lg:gap-4" data-sentry-component="WebsiteList" data-sentry-source-file="index.tsx">
      {websites.map(website => <WebsiteCard key={website.id} website={website} />)}
    </div>;
};