import { useUser } from '@/hooks/useUser';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import Link from 'next/link';
interface UserAvatarProps {
  size?: 'sm' | 'md' | 'lg';
  linkToAccount?: boolean;
  showName?: boolean;
}
export const UserAvatar: React.FC<UserAvatarProps> = ({
  size = 'md',
  linkToAccount = true,
  showName = false
}) => {
  const {
    user,
    loading,
    hasLogin
  } = useUser();
  if (!hasLogin) {
    console.log('hasLogin', hasLogin);
    return null;
  }
  if (loading) {
    return <div className="animate-pulse bg-gray-200 rounded-full" style={{
      width: getSize(size),
      height: getSize(size)
    }}></div>;
  }
  const avatarContent = <div className="flex items-center">
      <Avatar className={`${getSizeClass(size)} cursor-pointer hover:opacity-80 transition-opacity`}>
        {user ? <AvatarImage src={user.user_metadata.avatar_url || ''} alt={user.user_metadata.full_name || 'User Avatar'} /> : <AvatarImage src="/default-avatar.png" alt="Default Avatar" />}
        <AvatarFallback>
          {user ? user.user_metadata.full_name?.charAt(0) || user.user_metadata.username?.charAt(0) || 'User' : 'Guest'}
        </AvatarFallback>
      </Avatar>
      {showName && user && <span className="ml-2 text-sm font-medium">
          {user.user_metadata.full_name || user.user_metadata.username || 'User'}
        </span>}
    </div>;
  if (linkToAccount && user) {
    return <Link href="/account">{avatarContent}</Link>;
  }
  return avatarContent;
};
function getSizeClass(size: 'sm' | 'md' | 'lg') {
  switch (size) {
    case 'sm':
      return 'h-8 w-8';
    case 'md':
      return 'h-10 w-10';
    case 'lg':
      return 'h-12 w-12';
  }
}
function getSize(size: 'sm' | 'md' | 'lg') {
  switch (size) {
    case 'sm':
      return '2rem';
    case 'md':
      return '2.5rem';
    case 'lg':
      return '3rem';
  }
}