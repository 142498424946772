"use client";

import { useEffect, useState } from "react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { cn } from "@/lib/utils";
interface WordRotateProps {
  words: string[];
  duration?: number;
  framerProps?: HTMLMotionProps<"h1">;
  className?: string;
}
export default function WordRotate({
  words,
  duration = 2500,
  framerProps = {
    initial: {
      opacity: 0,
      y: -50
    },
    animate: {
      opacity: 1,
      y: 0
    },
    exit: {
      opacity: 0,
      y: 50
    },
    transition: {
      duration: 0.25,
      ease: "easeOut"
    }
  },
  className
}: WordRotateProps) {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex(prevIndex => (prevIndex + 1) % words.length);
    }, duration);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [words, duration]);
  return <div className="overflow-hidden py-2" data-sentry-component="WordRotate" data-sentry-source-file="word-rotate.tsx">
      <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-source-file="word-rotate.tsx">
        <motion.h1 key={words[index]} className={cn(className)} {...framerProps} data-sentry-element="unknown" data-sentry-source-file="word-rotate.tsx">
          {words[index]}
        </motion.h1>
      </AnimatePresence>
    </div>;
}