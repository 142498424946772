import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip';
const JoinIcon: React.FC<{
  className?: string;
  Icon: React.FC<React.SVGProps<SVGElement>>;
  href: string;
  title: string;
  label?: string;
}> = ({
  Icon,
  href,
  title,
  label,
  className
}) => {
  return <Tooltip delayDuration={0} data-sentry-element="Tooltip" data-sentry-component="JoinIcon" data-sentry-source-file="JoinIcon.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="JoinIcon.tsx">
        <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
          <Icon className="h-8 w-8" data-sentry-element="Icon" data-sentry-source-file="JoinIcon.tsx" />
          <span className="sr-only">{title}</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="bottom" className="flex items-center gap-4" data-sentry-element="TooltipContent" data-sentry-source-file="JoinIcon.tsx">
        {title}
        {label && <span className="ml-auto text-muted-foreground">{label}</span>}
      </TooltipContent>
    </Tooltip>;
};
export default JoinIcon;