import { Profile } from '@repo/common/entities';
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { cn } from '@/lib/utils';
export const UserAvatar: React.FC<{
  className?: string;
  user: Profile;
}> = ({
  className,
  user
}) => {
  if (!user) return null;
  return <Avatar className={className} data-sentry-element="Avatar" data-sentry-component="UserAvatar" data-sentry-source-file="UserAvatar.tsx">
      <AvatarImage src={user.avatar_url ?? undefined} alt={user.full_name ?? undefined} data-sentry-element="AvatarImage" data-sentry-source-file="UserAvatar.tsx" />
      <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="UserAvatar.tsx">{user.username || user.full_name}</AvatarFallback>
    </Avatar>;
};
export const UserInfo: React.FC<{
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  profile: Profile;
  avatarOnly?: boolean;
}> = ({
  className,
  size = 'md',
  profile,
  avatarOnly
}) => {
  if (!profile) return null;
  return <div className={cn('flex items-center gap-2', size === 'sm' ? 'text-sm gap-1' : size === 'md' ? 'text-base' : 'text-lg', className)} data-uid={profile.id} data-sentry-component="UserInfo" data-sentry-source-file="UserAvatar.tsx">
      <UserAvatar user={profile} className={cn(size === 'sm' ? 'w-6 h-6' : size === 'md' ? 'w-8 h-8' : 'w-10 h-10')} data-sentry-element="UserAvatar" data-sentry-source-file="UserAvatar.tsx" />
      {!avatarOnly && <div>{profile.username || profile.full_name}</div>}
    </div>;
};