import Link from 'next/link';
import React from 'react';
export const Footer: React.FC = () => {
  return <footer className="flex flex-col gap-2 justify-center items-center h-32 bg-gray-800 text-white" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <div className="flex gap-4 text-sm items-center">
        <Link href="https://createany.notion.site/Terms-of-Service-3b320e1f8c9e4c4ba813db3fcf0c199d?pvs=74" data-sentry-element="Link" data-sentry-source-file="index.tsx">Terms of Service</Link>
        <Link href="https://createany.notion.site/Privacy-Policy-a96a8549ead44215a79c730ee0b6623d?pvs=74" data-sentry-element="Link" data-sentry-source-file="index.tsx">Privacy Policy</Link>
      </div>
      <p className="text-sm">© 2024 CreateAny.ai</p>
    </footer>;
};