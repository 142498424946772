import WordRotate from '../magicui/word-rotate';
import DiscordIcon from '@/assets/icons/discord-round-black-icon.svg';
import EmailIcon from '@/assets/icons/email-round-black-icon.svg';
import JoinIcon from '../ui/JoinIcon';
import { UserAvatar } from './UserAvatar';
export const HomeHeader = () => {
  return <div className="relative mt-14 mb-20" data-sentry-component="HomeHeader" data-sentry-source-file="index.tsx">
      <div className="absolute top-0 right-0">
        <UserAvatar size="md" linkToAccount={true} showName={false} data-sentry-element="UserAvatar" data-sentry-source-file="index.tsx" />
      </div>
      <div className="flex flex-col gap-4">
        <h1 className="text-4xl font-bold">CreateAny.ai</h1>
        <WordRotate className="text-md font-bold text-black/40" words={['Implement Your Idea with a Single Line', 'Improve Your Website with the Community', "Let's Build Our Ideal Site Together"]} duration={4000} data-sentry-element="WordRotate" data-sentry-source-file="index.tsx" />
        <div className="text-sm text-gray-500 flex items-center gap-4">
          <JoinIcon Icon={DiscordIcon} href="https://discord.gg/7Frn6CGteY" title="Discord" data-sentry-element="JoinIcon" data-sentry-source-file="index.tsx" />
          <JoinIcon Icon={EmailIcon} href="mailto:hi@createany.ai" title="hi@createany.ai" data-sentry-element="JoinIcon" data-sentry-source-file="index.tsx" />
          <span className="text-sm font-bold">
            Any problem? join our Discord or email us!
          </span>
        </div>
      </div>
    </div>;
};